import React from "react";
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import _ from 'lodash';
import Seo from "../components/seo";// da
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import moment from "moment";
import Parts from '../components/parts';
import TextBlock from '../components/textblock';
import Contact from '../components/contact';
import HeroImage from "../components/HeroImage/heroimage";
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { withPrefix } from "gatsby";
import ServiceMenu from '../components/servicemenu';
import BlogList from '../components/bloglist';
import About from '../components/about';


export default function BlogsPage({ data }) {

      let aboutData = _.merge( _.pick(data.global,['id','company','address','duns','email','tel','twitter','web','tagLine']),
                             _.pick(data.global.body.data,['body'] )
                           )
      let siteData = _.merge( _.pick(data.siteMetadata.siteMetadata,['description','title','logo','siteUrl','posturl','captchaurl','tagLine','cookieDebug']))

      let blogsData =   data.blogs.nodes.map( item =>
                    _.merge( _.pick(item,['id','slug','title','description','date']),
                     _.pick(item.body.data,['body'] ),
                     { image: item.image.localFile })
      )

      let pageData = _.merge( _.pick(data.page.nodes[0],['id','description','title','slug','section','page','date','keywords']),
                              _.pick(data.page.nodes[0].body.data,['body']),
                              { image: data.page.nodes[0].image.localFile })
      //console.debug( pageData );
      let partsData = _.orderBy( data.page.nodes[0].parts.map( item => {
                    //console.debug( item );
                    return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                    { image: (item.image) ? item.image.localFile : null },
                                    { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
                  }),["order"],["asc"]  );

     const useHero = false;
     const textOptions = { margin: true, centre: false, centretitle: true, titlecapitalize: true }
    if (useHero) {
      return (
        <Layout header="Blogs" >
          <Seo  title={pageData.title}  keywords={pageData.keywords} description={pageData.description}/>
          <div className="site-container blogs-page" id={pageData.slug}>
            <div className="container">
              { (pageData.image !=null ) ? <HeroImage title={pageData.title} description={pageData.description} image={pageData.image.localFile.publicURL} /> : <div/> }

              <TextBlock title={pageData.description} mdstring={pageData.body} section="Blogs" options={ textOptions }/>
              <Parts key="Parts" anchor="parts" parts={partsData}/>

              <ul className={`blogs-list ${ blogsData.length < 5 ? "few-blogs" : "" }`} >
                {blogsData.map((item, index) => {
                  return (
                    <li key={index} className="item">
                      <div className="inner">
                        <Link className="link" to={`/${item.slug}`} />
                        {item.image ? ( <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt="image"  objectFit="cover" objectPosition="50% 50%" /> ) : (
                          <div className="no-image"></div>
                        )}
                        <div className="details">
                          <h3 className="title">{item.title}</h3>
                          <span className="date">
                            <i className="fas fa-calendar-alt"></i>{" "} {moment(item.date,  "YYYY-MM-DD").format("LL")}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Layout>
      );
    } else {
      const image = getImage(withPrefix(pageData.image));
      const bgImage = convertToBgImage(image);
      return (
        <Layout header="Blogs" >
          <Seo  title={pageData.title}  keywords={pageData.keywords} description={pageData.description}/>
          <div className="site-container " id={pageData.slug}>
            <div className="container blogs-container">

                    <div className="blogs-page">
                       <BackgroundImage      {...bgImage}     preserveStackingContext       >
                         <div className="blogs-content text-white overlay overlay-dark ">
                           <h2 className="title">{pageData.title}</h2>
                           <p className="date">
                             <i className="fas fa-calendar-alt"></i>{" "}
                               {moment(pageData.date,  "YYYY-MM-DD").format("LL")}
                           </p>

                           <div className="row">
                               <div className="col blogs-header">
                                 <div className="blogs-description ">
                                   <TextBlock title={pageData.description} mdstring={pageData.body} section="TextBlock" options={ textOptions }/>
                                 </div>
                               </div> {/*service-details */}

                               <div className="col blogs-detail">
                                   <GatsbyImage  className="blogs-image"  image={image} alt="image" objectFit="cover" objectPosition="50% 50%" />
                               </div>
                           </div> {/* row */}
                           <div className="blogs-footer text-white">

                          </div>
                         </div> {/*blogs-content*/}
                       </BackgroundImage>
                       <Parts key="Parts" anchor="parts" parts={partsData}/>
                       {/* <div className="blogs-footer"> */}
                        {/*   <div className="blogs-parts"> */}

                         {/* </div> */}
                        {/* </div> */}

                     </div> {/* blogs-page */}
                      <BlogList key="Blogs"></BlogList>
                      <About key="About" ></About>
                      <Contact key="Contact" ></Contact>
                   </div>
                 </div>
        </Layout>
      );
    }
}

export const pageQuery = graphql`
query {

    global: strapiGlobal {
      id
      company
      address
      duns
      email
      tel
      twitter
      web
      tagLine
      body {
        data {
          body
        }
      }
    }

    blogs: allStrapiBlog(sort: {fields: index, order: ASC}) {
      nodes {
        id
        slug
        title
        body {
          data {
            body
          }
        }
        description
        date
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    page: allStrapiPage(filter: {title: {glob: "*Blogs"}}) {
      nodes {
        id
        title
        strapi_id
        slug
        section
        page
        description
        date
        body {
          data {
            body
          }
        }
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        parts {
          id
          order
          page
          title
          text {
            data {
              text
            }
          }
          layouttype
          partId
          anchor
          options {
            parallax
            height
            centre
            centretitle
            margin
          }
          image {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
        }
      }
    }

      siteMetadata: site(siteMetadata: {}) {
        siteMetadata {
          description
          logo
          siteUrl
          title
          posturl
          captchaurl
          tagLine
          cookieDebug
        }
      }
}
`
