import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image"
import _ from 'lodash';
import { useStaticQuery, graphql } from "gatsby";
import MDRender from "./mdrender"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  icon,solid,brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
//import { faTwitter } from '@fortawesome/free-solid-svg-icons'

const About = ( ) => {

  const data = useStaticQuery(graphql`
    query {

      global: strapiGlobal {
        id
        company
        address
        duns
        email
        tel
        twitter
        linkedin
        web
        tagLine
        body {
          data {
            body
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }


  }
`)


  let aboutData = _.merge( _.pick(data.global,['id','company','address','duns','email','tel','twitter','web','tagLine','linkedin']),
                         _.pick(data.global.body.data,['body'] ),
                         { image: data.global.image.localFile }
                      )


  //let aboutData = _.merge( _.pick(data.mdx,['id','body']),
  //                         _.pick(data.mdx.frontmatter,["date","address","company","email","header","tel","twitter","web","linkedin","duns"] ),
  //                            { image: data.mdx.frontmatter.image } )
return (
  <div className="about section" id="About">
    <AnchorLink to="#About" title="" />
    <div className="container">
      <div className="about-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
        {aboutData.image ? (
          <GatsbyImage
            image={aboutData.image.childImageSharp.gatsbyImageData}
            alt="image"
            objectFit="cover"
            objectPosition="50% 50%"
          />
        ) : (
          <div className="no-image"></div>
        )}
        </div>
        <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <h2 className="name">{aboutData.tagLine}</h2>
            <div>
              <MDRender mdstring={aboutData.body}  ></MDRender>
            </div>
            <ul className="details">
              <li>
                <strong>Company</strong>
                <p>{aboutData.company}</p>
              </li>
              <li>
                <strong>Address</strong>
                <p>{aboutData.address}</p>
              </li>
              <li>
                <strong>Web</strong>
                <p>{aboutData.web}</p>
              </li>
              <li>
                <strong>Tel</strong>
                <p>{aboutData.tel}</p>
              </li>
              <li>
                <strong>DUNS:</strong>
                <p>{aboutData.duns}</p>
              </li>
              <li>
                <strong>Contact</strong>
                <p>
                  <a href={`mailto:${aboutData.email}`}>{aboutData.email}</a>
                </p>
              </li>
            </ul>
            <div className="socials">
              <ul>
                <li>
                  <a
                    href={aboutData.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  ><FontAwesomeIcon size="lg"  icon={brands('twitter')} /></a>
                </li>
                <li>
                  <a
                    href={aboutData.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  ><FontAwesomeIcon size="lg"  icon={brands('linkedin')} /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)};



export default About;
